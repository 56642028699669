import { useI18n } from 'vue-i18n';

export default function useTrans() {
  const i18n = useI18n();

  return {
    ...i18n,
    t: (token = '', params = {}) => {
      const trans = i18n.t(token, params, { missingWarn: false });
      return trans;
    },
    tList: (token = '') => {
      const listItems = i18n.t(token).split(/\r?\n|\r|\n/g);

      return listItems.reduce((acc, cur) => {
        acc.push({ text: cur });
        return acc;
      }, []);
    },
  };
}
